<template>
  <b-container>
    <b-row>
      
      <b-col lg="6" v-for="item, index in list" :key="index">     
        
        <b-card no-body class="overflow-hidden visita">
          <b-card-header :class="'bg-' + item.task_category.color">
           
           <!-- TITULO TARJETA -->
            <b-card-title>             
              {{ item.task_category.name + ' - ' + item.description }} 
              <span v-if="item.urgency == 1" class="bg-danger text-white">Urgente</span>
              
              <span v-else></span>
            </b-card-title>
          </b-card-header>
          <a :href="'/edit-task/'+item.id">
            <b-card-body>
              <!-- En proceso -->
              <div>
                <span class="mr-2">
                  <strong>Referencia servicio: </strong> {{ item.service_reference }}
                </span>
                <br>
                <span class="mr-2">
                 <p> <strong>Fecha y hora de inicio: </strong> {{ item.date_start ? formatTime(item.date_start) : 'AGENDAR' }} </p><br>
                  <a class="pl-5" :href="'tel:+34' + item.client.phone1">
                    <button class="btn btn-success" type="button" title="Llamar al cliente">
                      <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-phone-outgoing"
                        width="20" height="20" viewBox="0 0 24 24" stroke-width="1.5" stroke="#ffffff" fill="none"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                        <path d="M15 9l5 -5" />
                        <path d="M16 4l4 0l0 4" />
                      </svg>
                    </button>
                  </a>
                    
                </span>
                <br>
              </div>
            </b-card-body>
          </a>
        </b-card>

      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BRow, BCardHeader, BCard, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay } from 'bootstrap-vue'
import { mapState } from 'vuex';
export default {
  props: ['type'],
  watch: {
    // type: function() { 
    //   this.show = true;
    //   this.search = '',
    //   this.list = [];
    //   this.$http.get("/api/getNewTasks").then((response) => {
    //   // this.$http.get("/api/getNewTasks?user_id=" + this.user.id).then((response) => {
    //     this.list = response.data['tasks'];
    //     // this.status = response.data['status'];
    //     // this.types = response.data['types'];      
    //     this.$forceUpdate();
    //     this.show = false;
    //   }); 
    // }
  },
  components: { VBModal, BForm, BFormInput, BFormTextarea, BContainer, BCardHeader, BCard, BRow, BCardBody, BCardText, BButton, BCardTitle, BCol, BOverlay },
  data() {
    return {
      show: false,
      status: [],
      types: [],
      list: [],
      order: [],
      refuse_order: null,
      not_accepted: null,    
      search: ''
    }
  },
  created() {
    // this.show = true;
    this.$http.get("/api/getClosedTasks").then((response) => {
      // this.$http.get("/api/getNewTasks?user_id=" + this.user.id).then((response) => {
      this.list = response.data['tasks'];
      // this.status = response.data['status'];
      // this.types = response.data['types'];
      console.log(response.data)
      this.show = false;
    });
  },
  methods: {
    searchTask() {
      this.show = true;
      this.$http.get("/api/getNewTasks").then((response) => {
        // this.$http.get("/api/getNewTasks?user_id=" + this.user.id + "&search="+this.search).then((response) => {
        this.list = response.data['tasks'];
        // this.status = response.data['status'];
        // this.types = response.data['types'];
        this.show = false;
      });
    },
    searchReset() {
      this.search = '';
      this.searchTask();
    },
    // changeStatus(id, status){
    //   let $parameters = {scope : this.type, tech : this.user.technician.id, work_order : id , status : status}
    //   if(!status){
    //     $parameters.not_accepted = this.not_accepted;
    //   }
    //   let that = this;
    //   this.$http.post("/api/changeStatus", $parameters).then((response) => {       
    //     if(status) {
    //       this.$router.push({ name: 'workorders' })
    //     }else {
    //       this.list = response.data['orders'];
    //       this.$bvModal.hide('rechazarOrden');
    //       this.$bvToast.toast('Orden rechazada', { variant: 'danger',noCloseButton : true });
    //     }
    //     // this.show = false;
    //   });
    // },    


    formatTime(date) {
      let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
      let format_time = new Date(date).toLocaleTimeString('es-es', { hour: '2-digit', minute: '2-digit' });
      return format_date + ' ( ' + format_time + ' ) '
    },
    formatDate(date) {
      let format_date = new Date(date).toLocaleDateString('es-es', { year: 'numeric', month: '2-digit', day: '2-digit' })
      return format_date
    },
  }, computed: {
    ...mapState({
      // user: state => state.auth.user,   
    }),
  }
}
</script>
<style>
.notification-rounded {
  display: inline-block;
  width: 25px;
  height: 25px;
  color: white;
  padding: 5px;
  padding-top: 3px;
  border-radius: 25px;
  text-align: center;
}

.green {
  background-color: green;
}

.grey {
  background-color: grey;
}

.bg-light-pink {
  background-color: #ffadad !important;
}

.bg-celeste {
  background-color: #9bf6ff !important;
}

.bg-lemon-yellow {
  background-color: #fdffb6 !important;
}

.bg-tea-green {
  background-color: #caffbf !important;
}

.bg-deep-champagne {
  background-color: #ffd6a5 !important;
}

.bg-dark-green {
  background-color: #52b69a !important;
}

.bg-dark-green .card-title {
  color: white !important;
}
.estado3{
  background-color: rgb(100, 158, 29); 
  margin-left: 6px;
  color: white;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 25px;
}
.estado{
  background-color: rgb(241, 131, 58); 
  margin-left: 6px;
  color: white;
  border: 1px solid transparent;
  padding: 10px;
}
</style>